/* Import the relevant styles from TimePortal.css */
@keyframes slide-down {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-slide-down {
    animation: slide-down 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.portal-heading {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-family: 'Arial', sans-serif;
    text-shadow: 0 0 5px #4682B4,
                 0 0 10px #4682B4;
    position: relative;
}

.portal-heading::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #4682B4, #00BFFF, #4682B4);
    background-size: 400%;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s;
}

.portal-nav-button {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position: relative;
    overflow: hidden;
}

.portal-nav-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #4682B4, #00BFFF, #4682B4);
    background-size: 400%;
    animation: glowing 20s linear infinite;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s;
}

.portal-nav-button:hover::before {
    opacity: 1;
}

.portal-nav-button:disabled {
    cursor: not-allowed;
    transform: none;
    border-color: #64748b !important;
    color: #64748b !important;
    text-shadow: none;
    background-color: transparent !important;
}

.portal-nav-button:disabled:hover {
    transform: none;
    background-color: transparent !important;
    box-shadow: none;
}

.portal-nav-button:disabled::before {
    display: none;
}